import * as React from 'react'

interface IPropTypes {
  title: string
}

const PageHero = ({ title }: IPropTypes) => {
  return (
    <div className="max-w-3xl mx-auto mb-12 mt-4 h-44 px-4">
      <div className="bg-secondary h-full border border-4 border-tertiary flex justify-center">
        <h2 className="flex items-center align-center text-center text-4xl font-bold text-primary">
          {title}
        </h2>
      </div>
    </div>
  )
}

export default PageHero
