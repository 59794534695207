import * as React from 'react'

import Layout from '../components/core/Layout'
import PageHero from '../components/content/PageHero'

const ThankYou = () => {
  return (
    <Layout>
      <PageHero title="Thank You's" />
    </Layout>
  )
}

export default ThankYou
